<template>
    <div>
        <v-autocomplete
            v-model="$store.state.identifierId"
            v-debounce:500ms="getData"
            :items="items"
            item-text="identifierName"
            item-value="id"
            :loading="loading"
            :label="label"
            :rules="[required()]"
            dense 
            no-data-text="لا توجد بيانات"
            filled
            outlined
            hide-details="auto"
        />
    </div>
</template>

<script>
import validations from "@/utils/validations";
export default {
    data() {
        return {
            loading: false,
            items: [],
            search: "",
            ...validations,
        };
    },

    props: ["label"],

    created() {
        this.getData();
    },

    methods: {
        getData() {
            this.loading = true;
            this.$GenericService
                .getAll("identifier/getIdentifiers")
                .then(res => {
                    this.items = res.data.data;
                })
                .catch(err => {
                    // console.log(err);
                })
                .finally(() => (this.loading = false));
        }
    }
};
</script>
